<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ArchiveIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Product Categories</span>
        </template>
        <product-edit-tab-categories class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="Navigation2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Offices</span>
        </template>
         <user-edit-tab-information class="mt-2 pt-75" /> 
      </b-tab> -->

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon icon="ImageIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Galery</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
    <hr data-v-7749c12c="" class="invoice-spacing" />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../productStoreModule";
import UserEditTabAccount from "./ProductEditTabInfo.vue";
import UserEditTabInformation from "./ProductEditTabOffices.vue";
import UserEditTabSocial from "./ProductEditTabGalery.vue";
import ProductEditTabCategories from "./ProductTabCategories.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormDatepicker,
    BFormTextarea,
    BFormFile,
    ValidationProvider,
    ValidationObserver,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
    ProductEditTabCategories,
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-user/fetchProduct", {
        id: router.currentRoute.params.id,
        params: {
          idOffice: router.currentRoute.query.idOffice,
        }, 
      })
      .then((response) => {
        userData.value = response.data;
        console.log("que tiene ", userData.value);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
    };
  },
};
</script>

<style></style>
