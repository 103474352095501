import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getProducts", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchProduct(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getOneProduct/${id}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProduct(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addProduct", productData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error.response.data.msg));
      });
    },

    updateProduct(ctx, queryParams) {
      const id = queryParams.get("id");
      queryParams.delete("id");
      return new Promise((resolve, reject) => {
        axios
          .put(`/updateProduct/${id}`, queryParams, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              idOffice: queryParams.get("idOffice") || undefined,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProductStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const status = { status: queryParams.status };
        axios
          .put(`/updateProductStatus/${queryParams.id}`, { params: status })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteProduct(ctx, queryParams) {
      let cloverId = 0;
      let detProduct = 0;

      /// Esto es cuando viene de una oficina si no es 0 Por es borrado masivo
      if (queryParams.data.officeProducts.length > 0) {
        cloverId = queryParams.data.officeProducts[0].cloverId;
        detProduct = queryParams.data.officeProducts[0].id;
      }
      const data = {
        idRol: queryParams.data.idRol,
        idOffice: queryParams.data.idOffice,
        cloverId,
        detProduct,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteProduct/${queryParams.data.id}`, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchHelpers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelpes", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchProductCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getCategoryProduct", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelperSales")
          .then((response) => {
            const data = response.data.data.categories;

            resolve(data);
          })
          .catch((error) => reject(error));
      });
    },
    fetchProductsGalery(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getProductsGalery", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addProductGalery(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addProductGalery", productData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error.response.data.msg));
      });
    },
    deleteGaleria(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteProductGalery/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchProductsCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getCategoryProduct", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCategoriesProductos(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getCategories", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addProductCategory(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/createProdCategory", productData)
          .then((response) => resolve(response))
          .catch((error) => reject(error.response.data.msg));
      });
    },
    deleteProductCategory(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteProductCategory/${productData.id}`, productData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
