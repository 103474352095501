<template>
  <div>
    <div class="d-flex mb-2 mt-2 mb-2">
      <feather-icon icon="ImageIcon" size="19" />
      <h4 class="mb-0 ml-50">Add Image (Max Size 2Mb)</h4>
    </div>
    <b-media class="mb-2">
      <template #aside>
        <b-avatar :src="image" size="90px" rounded />
      </template>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            @change="uploadImage"
            class="d-none"
          />
          <span class="d-none d-sm-inline">Add Image</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>
    <div>
      <b-button variant="primary" @click.prevent="saveImage()">
        <span class="d-none d-sm-inline">Save Image</span>
        <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
      </b-button>
    </div>
    <div class="d-flex mb-2 mt-2 mb-2">
      <feather-icon icon="ImageIcon" size="19" />
      <h4 class="mb-0 ml-50">Galery</h4>
    </div>

    <b-row class="match-height">
      <b-col md="6" lg="4" v-for="item in productData" :key="item.id">
        <b-card :img-src="item.link_image" img-alt="Card image cap" img-top>
          <b-link class="card-link" @click="confirmDelete(item.id)">
            Delete
          </b-link>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'

import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  ref,
  onUnmounted,
  onMounted,
  onBeforeMount,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '../productStoreModule'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BImg,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BLink,
    BAvatar,
    BMedia,
  },
  methods: {
    // confirm texrt
    confirmDelete(data) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteImageGalery(data)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },

  setup() {
    onMounted(() => {
      getGalery()
      console.log('mounted in the composition api!')
    })
    const toast = useToast()
    const idP = router.currentRoute.params.id
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const image = ref('')
    const productData = ref({})

    const model = ref({
      id_product: idP,
      status: true,
    })

    const uploadImage = (e) => {
      let file = e.target.files[0]
      if (file.size > 2097152) {
        return toast({
          component: ToastificationContent,
          props: {
            title: `ERROR`,
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: `image exceeds the allowed weight 2Mb`,
            solid: true,
          },
        })
      }
      Object.assign(model.value, { file: file })
      viewImage(file)
    }

    const viewImage = (imageP) => {
      let reader = new FileReader()
      reader.onload = (e) => {
        image.value = e.target.result
      }
      reader.readAsDataURL(imageP)
    }

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {},
    )

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const getGalery = () => {
      store
        .dispatch('app-user/fetchProductsGalery', { idP })
        .then((response) => {
          productData.value = response.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const saveImage = () => {
      let formData = new FormData()
      formData.append('id_product', model.value.id_product)
      formData.append('status', model.value.status)
      formData.append('file', model.value.file)

      store
        .dispatch('app-user/addProductGalery', formData)
        .then((response) => {
          getGalery()
          toast({
            component: ToastificationContent,
            props: {
              title: `SUCCESS`,
              icon: 'CheckCircleIcon',
              text: `${response.data.msg}`,
              variant: 'success',
            },
          })
          //router.push({ name: 'apps-service-list' })
          image.value = ''
        })
        .catch((err) => {
          console.log('ERRRRO=>', { err })
          toast({
            component: ToastificationContent,
            props: {
              title: `ERROR`,
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: `${err}`,
              solid: true,
            },
          })
        })
    }

    return {
      idP,
      model,
      refInputEl,
      previewEl,
      inputImageRenderer,
      image,
      productData,
      uploadImage,
      saveImage,
      getGalery,
      deleteImageGalery: (id) => {
        store.dispatch('app-user/deleteGaleria', { id }).then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: `The Image was delete correctly`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          getGalery()
        })
      },
    }
  },
}
</script>

<style></style>
